.rangeWrapper {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  // justify-content: center;
  // width: calc(100% - 24px);
  width: 100%;
  padding: 0 17px;
  overflow: hidden;
  &__title {
    color: black;
    font-size: 18px;
    font-family: Heebo;
    font-weight: 500;
  }
  &__value {
    margin: 5px 0 10px;
    color: black;
    font-size: 18px;
    font-family: Heebo;
    font-weight: 500;
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px black solid;
    box-sizing: border-box;
    text-align: center;
  }

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}
.range {
  width: 100%;
  box-sizing: border-box;
  // overflow: hidden;
  input[type='range'] {
    width: 100%;
    -webkit-appearance: none;
    height: 5px;
    background: #d9d9d9;
    border-radius: 5px;
    background-image: linear-gradient(#61c38b, #61c38b);
    background-repeat: no-repeat;
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 24px;
    width: 24px;
    position: relative;
    opacity: 0;
    cursor: pointer;
  }

  input[type='range']::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

  &__content {
    position: relative;
    width: 100%;

    input[type='number'] {
      outline: none;
      border: none;
      font-family: 'Heebo';
      font-style: normal;
      width: 36px;
      font-size: 16px;
      text-align: center;

      &:focus {
        border: 1px solid black;
        padding: 1px 11px;
        display: flex;
        justify-content: center;
        align-items: center;
        label {
          top: -50px;
        }
      }
    }
    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    input[type='number'],
    input[type='number']:hover,
    input[type='number']:focus {
      appearance: none;
      -moz-appearance: textfield;
    }

    label {
      position: absolute;
      top: -35px;
      width: 100%;
      pointer-events: none;
    }

    span {
      display: flex;
      justify-content: center;
      width: 1px;
      position: relative;
      height: 30px;

      &::before {
        content: '';
        pointer-events: none;
        width: 24px;
        height: 24px;
        position: absolute;
        top: 35px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 50%;
        background-color: white;
        border: 3px solid #61c38b;
        cursor: pointer;
        box-shadow: 0 0 2px 0 #555;
        transition: background 0.3s ease-in-out;
      }
    }
  }
  &__infoValues {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    &-value {
      font-family: Heebo;
      color: #d9d9d9;
      font-size: 16px;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
