.save-project-modal {
  position: relative;
  max-width: 695px;
  width: calc(100% - 42px);
  text-align: left;

  &__content {
    display: flex;
    column-gap: 16px;
    background: #ffffff;
    padding: 45px;

    .app-btn {
      max-width: 171px;
    }
  }

  &__input {
    flex: 1;
  }
}

@media screen and (max-width: 436px) {
  .save-project-modal {
    &__content {
      padding: 17px;
    }
  }
}
