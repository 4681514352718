.step4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  height: calc(100% - 136px);
  //padding: 0 30px;

  .simplebar-scrollable-y {
    max-height: calc(100% - 290px) !important;
  }

  &__editModel {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 50px;
    margin-top: 17px;

    &.mob {
      display: none;
    }

    &-title {
      display: flex;
      align-items: center;
      // gap: 8px;
      padding: 0 30px;

      svg {
        margin-left: 10px;
      }

      &-icon {
      }
    }
    &-editWrap {
      display: flex;
      justify-content: center;

      &-edit {
        border: 3px #bbb6b6 solid;
        width: 259px;
        height: 143px;
        position: relative;

        &-btnWrap {
          position: absolute;
          display: flex;
          gap: 12px;

          &-editBtn {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px black solid;
            box-sizing: border-box;
            background: white;
            cursor: pointer;
          }
          .editBtnActive {
            background: #61c38b;
            border: 1px #61c38b solid;
          }
          &-delBtn {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px black solid;
            box-sizing: border-box;
            background: white;
            cursor: pointer;
            position: relative;
          }
        }
        .btnWrapActive {
          z-index: 1;
        }
        .top {
          left: 50%;
          top: -20px;
          transform: translateX(-50%);
        }
        .right {
          top: 50%;
          transform: translateY(-50%);
          right: -20px;
          flex-direction: column;
        }
        .left {
          top: 50%;
          transform: translateY(-50%);
          left: -20px;
          flex-direction: column;
        }
        .bottom {
          left: 50%;
          bottom: -20px;
          transform: translateX(-50%);
        }
        .up {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
  &__line {
    width: 195px;
    border: 1px #bbb6b6 solid;
    margin-top: 50px;

    &.mob {
      display: none;
    }
  }
  &__elementWrap {
    overflow: auto;
    width: 100%;
    padding-bottom: 16px;
  }
}

.step4__delete-icon-btn {
  display: none;
  width: 40px;
  height: 40px;

  align-items: center;
  justify-content: center;
  border: 1px black solid;
  box-sizing: border-box;
  background: white;
  cursor: pointer;
  position: relative;
}

@media screen and (max-width: 1024px) {
  .step4__delete-icon-btn {
    display: flex;
  }

  .step4 {
    .simplebar-scrollable-y {
      max-height: 100% !important;
    }

    &__editModel {
      display: none;

      &.mob {
        display: flex;
      }

      &-editWrap {
        &-edit {
          &-btnWrap {
            &-delBtn {
              display: none;
            }
          }
        }
      }
    }
    &__line {
      display: none;
      &.mob {
        display: flex;
        margin: 50px auto 0;
      }
    }
  }

  .popUpDel {
    display: none;
  }
}

@media screen and (max-width: 436px) {
  .step4 {
    &__editModel-title {
      padding: 0 17px;
    }
  }

  .accordion-item {
    padding: 0 17px;
  }
}
