.quotes-summary-button {
  border: 1px solid #000;
  font-size: 12px;
  padding: 5px 27px 4px 27px;
  border-radius: 12px;
}

.quote-modal {
  position: relative;
  padding: 20px 20px 30px;
  text-align: left;
  max-width: 1000px;
  width: 100%;

  &__grid {
    display: grid;
    grid-template-columns: 200px 1fr 1fr 1fr;
    grid-template-rows: 48px 1fr 1fr 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    flex: 1;

    & > div {
      &:nth-child(1) {
        grid-area: 1 / 1 / 2 / 3;
      }
      &:nth-child(2) {
        grid-area: 1 / 3 / 2 / 4;
      }
      &:nth-child(3) {
        grid-area: 1 / 4 / 2 / 5;
      }
      &:nth-child(4) {
        grid-area: 2 / 1 / 5 / 3;
        padding: 30px 18px 0;
      }
      &:nth-child(5) {
        grid-area: 2 / 3 / 5 / 5;
        padding: 30px 18px 0;
      }
    }
  }

  &__title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 30px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    span {
      font-weight: 700;
      font-size: 24px;
      color: #717171;
    }

    &-number {
      font-size: 20px;
      font-weight: 500;
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    &-preview {
      width: 40%;
      height: 100%;
      padding-right: 20px;

      img {
        display: block;
        width: auto;
        max-width: 100%;
        max-height: 220px;
      }
    }

    &-table {
      flex: auto;

      &__head {
        display: flex;
        grid-gap: 10px;
        justify-content: space-between;

        b {
          font-weight: bold;
          flex: auto;
          width: 20%;
          margin-bottom: 10px;
          text-align: center;

          &:first-child {
            width: 40%;
            text-align: left;
          }

          &:last-child {
            text-align: right;
          }
        }
      }

      &__body {
        overflow-y: auto;
        max-height: 180px;
        scrollbar-face-color: #000000;
        scrollbar-arrow-color: #000000;
        scrollbar-track-color: #000000;
        scrollbar-shadow-color: #000000;
        scrollbar-highlight-color: #000000;
        scrollbar-3dlight-color: #000000;
        scrollbar-darkshadow-color: #000000;
        //background-color: var(--bg-color-dark);

        &::-webkit-scrollbar {
          width: 3px;
        }
        &::-webkit-scrollbar-track {
          background: transparent;
          padding-right: 13px;
        }

        &::-webkit-scrollbar-thumb {
          background: #e0e0e0;
          border-radius: 2px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #e0e0e0;
        }

        &-tr {
          padding: 5px 0;
          display: flex;
          grid-gap: 10px;
          align-items: center;
          justify-content: space-between;
        }

        &-td {
          flex: auto;
          width: 20%;
          text-align: center;

          &:first-child {
            width: 40%;
            text-align: left;
          }

          &:last-child {
            text-align: right;
          }
        }
      }
    }

    &-client-info {
      b {
        font-weight: bold;
      }

      & > div {
        padding: 10px 0;

        &:first-child {
          padding-top: 0;
        }
      }
    }
  }

  &__select {
    background: #f4f4f4;
    padding: 10px 18px;
    color: black;
    font-size: 16px;

    .app-select__wrapper {
      width: fit-content;
      max-width: none;
      margin-left: auto;

      .app-select__label {
        white-space: nowrap;
      }
    }
  }

  &__table {
    &-title {
      background: #f4f4f4;
      padding: 10px 18px;
      color: #6f6c6c;
      font-size: 22px;
      font-weight: 500;
      display: flex;

      &__item {
        &:first-child {
          width: 40%;
        }
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    grid-gap: 20px;

    &-comment {
      flex: 1;
      width: 100%;
      max-width: 630px;
      display: flex;
      align-content: flex-start;

      textarea {
        box-sizing: border-box;
        border: 1px solid #929090;
        display: block;
        width: 100%;
        outline: none;
        padding: 7px 10px;
        height: 100%;
        color: #747474;
        resize: none;
        font-size: 14px;

        &::placeholder {
          color: #747474;
        }
      }
    }

    &-total {
      padding-top: 20px;
      border-top: 2px solid #e0e0e0;
      margin-bottom: 25px;
      margin-left: auto;
      text-align: right;
      padding-left: 30px;

      p {
        margin-bottom: 15px;
      }

      strong {
        font-weight: bold;
      }
    }

    &-actions {
      width: 100%;
      max-width: 534px;
    }

    &-btn {
      display: flex;
      grid-gap: 10px;
      justify-content: flex-end;

      button {
        padding: 5px 30px;
      }
    }
  }
}

.container-prices {
  height: 200px;
  overflow: scroll;

  &::-webkit-scrollbar {
    width: 7px;
    height: 0px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #61c38b;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #61c38b;
  }
}
