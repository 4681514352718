.quotes-table {
  width: calc(100%);
  height: 100%;
  overflow: auto;

  table {
    border-collapse: separate;
    border-spacing: 0;
    min-width: 800px;
    margin-bottom: 10px;

    width: calc(100% - 10px);

    * {
      box-sizing: border-box;
    }

    thead {
      tr {
        th {
          padding: 5px 10px;
          color: #717171;
          font-weight: 500;
          font-size: 21px;
          line-height: 31px;

          &:nth-child(2) {
            padding-left: 0;
          }

          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5) {
            text-align: center;
          }

          &:last-child {
            max-width: 20%;
            text-align: right;

            .can-sort span {
              justify-content: flex-end;
            }
          }
        }
      }
    }

    tbody {
      tr {
        &:nth-child(2n + 1) {
          background: #f4f4f4;
        }

        td {
          padding: 5px 10px;

          &:nth-child(1) {
            width: 30%;
          }

          &:nth-child(2) {
            padding-left: 0;
            width: 30%;
          }

          &:nth-child(3) {
            width: 30%;
          }

          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5) {
            text-align: center;
          }

          &:last-child {
            text-align: left;
          }

          .revised {
            color: #080e8d;
          }
        }
      }
    }

    &.admin {
      tbody {
        tr {
          td {
            &:nth-child(1) {
              width: 10%;
            }

            &:nth-child(2) {
              width: 25%;
            }

            &:nth-child(3) {
              width: 15%;
            }

            &:nth-child(4) {
              width: 20%;
            }

            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5) {
              text-align: center;
            }

            &:last-child {
              text-align: left;

              .projects-table__actions {
                width: 100%;
                justify-content: flex-end;
              }
            }
          }
        }
      }
    }
  }

  .export-models {
    display: flex;
    justify-content: center;

    div {
      cursor: pointer;

      &:first-child {
        border-right: 1px solid;
        padding-right: 15px;
        margin-right: 15px;
      }
    }
  }

  &__pagination {
    margin: 20px 0 0 auto;
    max-width: fit-content;

    button {
      background: none;
      border: none;
      color: #222222;
      margin: 0 3px;
      cursor: pointer;

      &:disabled {
        opacity: 0.3;
        cursor: not-allowed;
      }
    }
  }

  &__status {
    font-weight: bold;

    &.Draft {
      color: #000000;
    }
    &.InProgress {
      color: #080e8d;
    }
    &.Finalized {
      color: #00a448;
    }
    &.Canceled {
      color: #ee0003;
    }
  }
}
