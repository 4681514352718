.step5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 37px;
  box-sizing: border-box;
  height: calc(100% - 136px);
}

.step5__farbaWrap {
  margin-top: 17px;
  overflow: auto;
  width: 100%;
  padding-bottom: 111px;
  display: flex;
  flex-direction: column;
  gap: 50px;
}
