.app-btn {
  outline: none;
  border: none;
  cursor: pointer;
  background: none;
  display: inline-block;
  font-family: "Heebo", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  padding: 0;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.border {
    background: #ffffff;
    color: var(--main-color);
    border: 1px solid var(--main-color);
  }

  &.black-border {
    background: #ffffff;
    color: black;
    border: 1px solid black;
  }

  &.green {
    background: var(--main-color);
    color: #ffffff;
  }

  &.red {
    background: #B80011;
    color: #ffffff;
  }

  &.grey {
    background: #D9D9D9;
    color: inherit;
  }

  &.big {
    padding: 20px;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.small {
    width: 60px;
    min-width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.border {
      border-color: #000;
    }
  }

  &.x-small {
    width: 30px;
    min-width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.border {
      border-color: #000;
    }
  }
}
