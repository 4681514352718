.wrapper-site-redirect-modal {
  width: calc(100% - 42px);
  max-width: 695px;
  position: relative;
}

.container-site-redirect-modal {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 30px;
}

.description-container-site-redirect-modal {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
}

.container-btnStart-site-redirect-modal {
  margin-top: 100px;
}

.btn-site-redirect-modal {
  background-color: #61c38b;
  color: white;
  width: 185px;
  height: 60px;
  position: absolute;
  right: 30px;
  bottom: 30px;
}

.site-redirect-modal-input {
  width: 100%;
  display: flex;
  height: 60px;
  box-sizing: border-box;
  margin-top: 20px;

  input {
    outline: none;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    border: 3px solid #61c38b;
    width: 100%;
    padding-left: 10px;
  }
}
