.change-password-modal {
  max-width: 585px;
  width: calc(100% - 42px);
  position: relative;

  &__content {
    padding: 35px 45px 45px;
    display: flex;
    flex-direction: column;
    row-gap: 25px;

    .app-btn {
      max-width: 185px;
      align-self: flex-end;
    }
  }
}

@media screen and (max-width: 436px) {
  .change-password-modal {
    &__content {
      padding: 17px;
    }
  }
}
