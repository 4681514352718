.wrapper-type-zip-modal {
  width: calc(100% - 42px);
  max-width: 695px;
  position: relative;
}

.container-type-zip-modal {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 30px;
}

.container-btnStart-type-zip-modal {
  margin-top: 100px;
}

.btn-type-zip-modal {
  background-color: #61c38b;
  color: white;
  width: 185px;
  height: 60px;
  position: absolute;
  right: 30px;
  bottom: 30px;
}

.section-type-zip-modal {
  margin-bottom: 22px;
}
.title-section-type-zip-modal {
  margin-bottom: 22px;
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  svg {
    margin-left: 5px;
  }
}
.content-section-type-zip-modal {
  display: flex;
  flex-wrap: wrap;
}
.type-zip-modal-options-img {
  width: 171px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-right: 40px;
  margin-top: 10px;

  &.disable {
    pointer-events: none;
  }
}
.type-zip-modal-options-content {
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #d8d8d8;
  box-sizing: border-box;
  &:hover {
    border-color: #61c38b;
  }
  &.active {
    border-color: #61c38b;
  }
  img {
    height: 100%;
    width: 100%;
  }
}

.type-zip-modal-options-title {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  &.toptitle {
    font-weight: 700;
  }
}

.toggle-btn-zip {
  display: none;
}
.wrapper-toggle-btn-zip label {
  position: relative;
  cursor: pointer;
  display: block;
  width: 50px;
  height: 30px;
  background: #cccccc;
  border-radius: 50px;
  transition: 300ms linear;
}

.wrapper-toggle-btn-zip label:before {
  position: absolute;
  top: 50%;
  left: 2px;
  transform: translateY(-50%);
  content: '';
  display: block;
  width: 25px;
  height: 25px;
  background: #ffffff;
  border-radius: 50%;
  transition: left 300ms linear;
}

.wrapper-toggle-btn-zip .toggle-btn-zip:checked + label {
  background: #61c38b;
}

.wrapper-toggle-btn-zip .toggle-btn-zip:checked + label:before {
  left: 23px;
}

.title-section-type-zip-modal-tgl-btn {
  margin-bottom: 22px;
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 26px;
}

@media screen and (max-width: 523px) {
  .content-section-type-zip-modal {
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .type-zip-modal-options-img {
    margin-right: 0;
  }
}

@media screen and (max-width: 436px) {
  .container-type-zip-modal {
    padding: 17px;
  }
  .container-btnStart-type-zip-modal {
    margin-top: 80px;
  }
  .btn-type-zip-modal {
    bottom: 17px;
    right: 17px;
  }
}
