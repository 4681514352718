.step6 {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  height: calc(100% - 136px);
}

.step6__lineWrap {
  display: flex;
  justify-content: center;
  margin: 22px 0 27px 0;
}

.step6__lineWrap-line {
  border: 1px #bbb6b6 solid;
  width: 195px;
}

.step6__infoWrap {
  overflow: auto;
  width: 100%;
  padding-bottom: 16px;
  // margin-top: 17px;
}

.step6__infoWrap-title {
  color: black;
  font-size: 18px;
  font-family: Heebo;
  font-weight: 300;
  margin-bottom: 34px;
  padding: 0 27px;
}

.step6__infoWrap-infoBlock {
  overflow: auto;
  padding: 0 27px;
  margin-top: 24px;
}

.step6__infoWrap-infoBlock-info-item {
  &-titleWrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  &-title {
    color: black;
    font-size: 18px;
    font-family: Heebo;
    font-weight: 500;
  }

  &-count {
    color: black;
    font-size: 18px;
    font-family: Heebo;
    font-weight: 700;
  }

  &-descriptionWrap {
    display: flex;
    flex-direction: column;
    gap: 11px;
    max-width: 275px;
  }

  &-description {
    color: black;
    font-size: 18px;
    font-family: Heebo;
    font-weight: 300;
  }
}
