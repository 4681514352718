.ModalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    background: #1e2534;
    opacity: 0.5;

    &.default {
      cursor: default;
    }
  }

  &__content {
    z-index: 1;
    background: #ffffff;
    box-sizing: border-box;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: 24px;
    line-height: 35px;
    background: #eeeeee;
    padding: 25px 0 25px 30px;
  }

  &__close {
    top: 0px;
    right: 44px;
    cursor: pointer;
    content: '';
    position: relative;

    &:before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 44px;
      height: 6px;
      background-color: black;
    }

    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}

@media screen and (max-width: 436px) {
  .ModalWrapper {
    &__header {
      padding: 11px 0 11px 23px;
    }

    &__close {
      top: 1px;
      right: 28px;
      &:before,
      &::after {
        width: 33px;
        height: 4px;
      }
    }
  }
}
