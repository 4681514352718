.wrapper-info-modal {
  width: calc(100% - 42px);
  max-width: 695px;
  position: relative;
}

.container-info-modal {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 30px;
}

.description-container-info-modal {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
}

.container-btnStart-info-modal {
  margin-top: 120px;
}

.btn-info-modal {
  background-color: #61c38b;
  color: white;
  width: 185px;
  height: 60px;
  position: absolute;
  right: 30px;
  bottom: 30px;
}

.header-info-modal {
  &:before,
  &::after {
    background-color: #ffffff !important;
  }
}

@media screen and (max-width: 436px) {
  .container-info-modal {
    padding: 17px;
  }

  .btn-info-modal {
    right: 17px;
    bottom: 17px;
  }
}
