.wrapper-type-lamellendach-modal {
  width: calc(100% - 42px);
  max-width: 714px;
  position: relative;
}

.title-amellendach-modal {
  font-family: Heebo;
  font-size: 20px;
  font-weight: 500;
  line-height: 29.38px;
  text-align: center;
  padding-bottom: 26px;
}

.container-type-lamellendach-modal {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 30px;
}
.items-lamellendach-modal {
  gap: 30px;
  display: flex;
}

.item-lamellendach-modal {
  width: 50%;

  img {
    border: 3px solid white;
    width: calc(100% - 6px);
    cursor: pointer;
    user-select: none;
  }

  &.active {
    img {
      border-color: #61c38b;
    }
  }
}

.title-item-lamellendach-modal {
  font-family: Heebo;
  font-size: 20px;
  font-weight: 500;
  line-height: 29.38px;
  text-align: center;
  text-transform: uppercase;
  padding-top: 11px;
  padding-bottom: 34px;
}

.list-item-lamellendach-modal {
  font-family: Heebo;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  height: 147px;

  ul {
    margin: 0;
    li span {
      font-weight: 600;
    }
  }
  margin-bottom: 34px;
}

.btn-type-lamellendach-modal {
  background-color: #61c38b;
  color: white;
  width: 100%;
  height: 60px;
}
