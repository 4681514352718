.reset-password-modal {
  max-width: 509px;
  width: calc(100% - 42px);
  position: relative;

  &__content {
    padding: 45px;
  }

  &__input {
    margin-bottom: 25px;
  }

  &__footer {
    margin-top: 40px;
  }

  .app-btn {
    max-width: 200px;
    margin-left: auto;
  }
}
@media screen and (max-width: 436px) {
  .reset-password-modal {
    &__content {
      padding: 17px;
    }
  }
}
