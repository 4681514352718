.wrapper-type-lamellen-modal {
  width: calc(100% - 42px);
  max-width: 695px;
  position: relative;
}

.container-type-lamellen-modal {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 30px;
}

.container-btnStart-type-lamellen-modal {
  margin-top: 100px;
}

.btn-type-lamellen-modal {
  background-color: #61c38b;
  color: white;
  width: 185px;
  height: 60px;
  position: absolute;
  right: 30px;
  bottom: 30px;
}

.section-type-lamellen-modal {
  margin-bottom: 22px;
}
.title-section-type-lamellen-modal {
  margin-bottom: 22px;
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  svg {
    margin-left: 5px;
  }
}
.content-section-type-lamellen-modal {
  display: flex;
  flex-wrap: wrap;
}

.input-type-lamellen-modal {
  width: calc(100% - 2px);
  padding-bottom: 22px;
  input[type='range'] {
    width: 100%;
    -webkit-appearance: none;
    height: 5px;
    background: #d9d9d9;
    border-radius: 5px;
    background-image: linear-gradient(#61c38b, #61c38b);
    background-repeat: no-repeat;
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background-color: white;
    border: 3px solid #61c38b;
    cursor: pointer;
    box-shadow: 0 0 2px 0 #555;
    transition: background 0.3s ease-in-out;
  }

  input[type='range']::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }
}

.titles-input-type-lamellen-modal {
  width: 100%;
  display: flex;
  justify-content: space-between;

  margin-top: 10px;

  //   margin-left: 5px;

  span {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.input-type-lamellen-modal-value-w {
  user-select: none;
  color: white;

  &.active {
    color: black;
  }
}

.input-type-lamellen-modal-value-g {
  user-select: none;
  color: #d8d8d8;

  &.active {
    color: black;
  }
}

.input-model-size-modal {
  &.lamellen {
    width: calc(100% - 5px);
    margin-top: 15px;
  }
}

@media screen and (max-width: 436px) {
  .container-type-lamellen-modal {
    padding: 17px;
  }
  .container-btnStart-type-lamellen-modal {
    margin-top: 80px;
  }
  .btn-type-lamellen-modal {
    bottom: 17px;
    right: 17px;
  }

  .section-type-lamellen-modal {
    margin-bottom: 0;
  }
}
