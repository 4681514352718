.registration-modal {
  max-width: 873px;
  width: calc(100% - 42px);
  position: relative;

  &__content {
    padding: 45px;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    margin-right: 12px;
  }

  &__input {
    &:nth-child(1) {
      grid-area: 1 / 1 / 2 / 3;
    }
    &:nth-child(2) {
      grid-area: 1 / 3 / 2 / 5;
    }
    &:nth-child(3) {
      grid-area: 2 / 1 / 3 / 4;
    }
    &:nth-child(4) {
      grid-area: 2 / 4 / 3 / 5;
    }
    &:nth-child(5) {
      grid-area: 3 / 1 / 4 / 2;
    }
    &:nth-child(6) {
      grid-area: 3 / 2 / 4 / 5;
    }
    &:nth-child(7) {
      grid-area: 4 / 1 / 5 / 5;
    }
    &:nth-child(8) {
      grid-area: 5 / 1 / 6 / 5;
    }
    &:nth-child(9) {
      grid-area: 6 / 1 / 7 / 3;
    }
    &:nth-child(10) {
      grid-area: 6 / 3 / 7 / 5;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-top: 21px;

    p {
      font-family: 'Heebo', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
    }
  }

  .app-btn {
    max-width: 200px;
    margin-left: auto;
    margin-right: 12px;
  }
}

@media screen and (max-width: 436px) {
  .registration-modal {
    &__content {
      padding: 17px;
    }

    &__input {
      &:nth-child(1) {
        grid-area: 1 / 1 / 2 / 3;
      }
      &:nth-child(2) {
        grid-area: 1 / 3 / 2 / 5;
      }
      &:nth-child(3) {
        grid-area: 2 / 1 / 2 / 3;
      }
      &:nth-child(4) {
        grid-area: 2 / 3 / 3 / 5;
      }
      &:nth-child(5) {
        grid-area: 3 / 1 / 3 / 3;
      }
      &:nth-child(6) {
        grid-area: 3 / 3 / 4 / 5;
      }
      &:nth-child(7) {
        grid-area: 4 / 1 / 5 / 5;
      }
      &:nth-child(8) {
        grid-area: 5 / 1 / 6 / 5;
      }
      &:nth-child(9) {
        grid-area: 6 / 1 / 6 / 5;
      }
      &:nth-child(10) {
        grid-area: 7 / 1 / 7 / 5;
      }
    }
  }
}
