.profile-update-form {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  &__row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 18px;

  }

  &__col {
    display: flex;

    &:nth-child(1) {
      grid-area: 1 / 1 / 2 / 3;
    }
    &:nth-child(2) {
      grid-area: 1 / 3 / 2 / 5;
    }
    &:nth-child(3) {
      grid-area: 2 / 1 / 3 / 4;
    }
    &:nth-child(4) {
      grid-area: 2 / 4 / 3 / 5;
    }
    &:nth-child(5) {
      grid-area: 3 / 1 / 4 / 2;
    }
    &:nth-child(6) {
      grid-area: 3 / 2 / 4 / 5;
    }
    &:nth-child(7) {
      grid-area: 4 / 1 / 5 / 5;
    }
    &:nth-child(8) {
      grid-area: 5 / 1 / 6 / 5;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    margin-top: 20px;
    font-weight: 500;
    font-size: 20px;

    .app-btn {
      max-width: 185px;
      margin-right: 12px;
    }
  }
}

@media screen and (max-width: 767px) {
  .profile-update-form {
    &__col {
      display: flex;

      &:nth-child(1) {
        grid-area: 1 / 1 / 2 / 3;
      }
      &:nth-child(2) {
        grid-area: 1 / 3 / 2 / 5;
      }
      &:nth-child(3) {
        grid-area: 2 / 1 / 3 / 3;
      }
      &:nth-child(4) {
        grid-area: 2 / 3 / 3 / 5;
      }
      &:nth-child(5) {
        grid-area: 3 / 1 / 4 / 3;
      }
      &:nth-child(6) {
        grid-area: 3 / 3 / 4 / 5;
      }
      &:nth-child(7) {
        grid-area: 4 / 1 / 5 / 5;
      }
      &:nth-child(8) {
        grid-area: 5 / 1 / 6 / 5;
      }
    }
    &__footer {
      flex-direction: column;
      // align-items: flex-start;
      align-items: flex-end;

      flex-direction: column-reverse;

      span {
        width: 100%;
        margin-top: 15px;
      }
    }
  }
}
