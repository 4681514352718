.sidePanel {
  width: 100%;
  max-width: 436px;
  z-index: 12;
  position: relative;

  // position: absolute;

  background: white;
  height: calc(100dvh - 98px);
  // padding: 24px 0 0 0;
  box-sizing: border-box;

  //display: flex;
  //flex-direction: column;
  //justify-content: space-between;
}

@media screen and (max-width: 1024px) {
  .sidePanel {
    max-width: 100%;
    height: calc(100dvh - 118px);

    &.full {
      height: calc(100dvh - 50% - 58px);
    }
  }
}
