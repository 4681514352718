.date-picker {
  position: relative;
  display: flex;
  align-items: center;
  grid-gap: 10px;

  .react-datepicker-wrapper {
    max-width: 174px;
    width: 100%;
    background: #D9D9D9;
    border-radius: 10px;
    position: relative;

    &:before {
      content: '';
      display: block;
      width: 12px;
      height: 15px;
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translateY(-50%);
      background: url('../../../assets/select-icon.svg') no-repeat center/contain;
    }
  }

  .react-datepicker__tab-loop {
    position: absolute;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--today {
    background: #343434!important;
    color: white;
    border-radius: 0!important;
  }

  .react-datepicker__close-icon::after {
    background: #343434;
  }

  input {
    width: 100%;
    background: transparent;
    border: none;
    padding: 3px 20px 3px 8px;
    outline: none;
    color: black;
    height: 24px;
    font-size: 16px;
    font-weight: 500;

    &::placeholder {
      color: black;
      font-size: 16px;
      font-weight: 500;
    }
  }

  &__value {
    color: #494151;
    font-size: 18px;
    display: flex;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;

    &:after {
      content: '';
      width: 18px;
      height: 18px;
      display: block;
      background-repeat: no-repeat;
      background-size: cover;
      background-color: #494151;
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: contain;
      margin-left: 20px;

      mask-image: url('../../../assets/calendar-arrow.svg');
    }
  }

  &__modal {
    position: absolute;
    top: calc(100% + 20px);
    right: 0;
    min-width: 225px;
    z-index: 10;
  }

  &__label {
    font-weight: bold;
  }
}
