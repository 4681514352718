.wrapper-iframe-modal {
  width: calc(100% - 42px);
  max-width: 1440px;
  height: 90%;
  position: relative;
}

.container-iframe-modal {
  display: flex;
  width: 100%;

  height: calc(100% - 85px);
  justify-content: space-between;
  flex-direction: column;
}

.iframe-danke {
  border: none;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 436px) {
  .container-iframe-modal {
    height: calc(100% - 57px);
  }
}
