.wrapper-upload-bg-modal {
  width: calc(100% - 42px);
  max-width: 695px;
  position: relative;
}

.container-upload-bg-modal {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 30px;
}

.description-container-upload-bg-modal {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
}

.container-btn-upload-bg-modal {
  margin-top: 90px;
}

.btn-upload-bg-modal {
  background-color: #61c38b;
  color: white;
  width: 185px;
  height: 60px;
  position: absolute;
  right: 30px;
  bottom: 30px;
}

@media screen and (max-width: 436px) {
  .container-warning-modal {
    padding: 17px;
  }

  .btn-warning-modal {
    right: 17px;
    bottom: 17px;
  }
}

///

.file-upload-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.file-upload-label {
  padding: 20px 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 147px;
  border: 1px dashed #61c38b;
  box-sizing: border-box;
  cursor: pointer;

  &.active {
    cursor: auto;
    height: auto;
  }
}

.file-upload-icon {
  width: 50px;
  height: 50px;
  margin-bottom: 8px;
}

.file-upload-text {
  font-size: 16px;
  color: #7e838b;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  span {
    text-decoration-line: underline;
    color: #61c38b;
  }
}

.file-upload-input {
  display: none;
}

.progress-bar {
  width: 100%;
  height: 5px;
  background-color: #eee;
  border-radius: 5px;
  margin-top: 10px;
  overflow: hidden;
}

.progress {
  height: 100%;
  width: 0;
  background-color: #61c38b;
  border-radius: 5px;
  transition: width 0.3s ease-in-out;
}

.upload-content {
  width: 100%;
  padding: 0 100px;
  display: flex;
  justify-content: space-between;
  img {
    width: 105px;
    height: 105px;
    margin-right: 8px;
    border: 1px solid #61c38b;
    box-sizing: border-box;
    margin-bottom: 0;
  }
  .wrapper-file-upload-text-remove {
    width: calc(100%);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .file-upload-text-remove {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      i {
        cursor: pointer;
      }
    }
  }

  .file-upload-text {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #1c1d1f;
  }
}

.delete-upload-bg {
  text-decoration: none;
  border: none;
  width: 32px;
  height: 32px;
  cursor: pointer;
  user-select: none;
  position: absolute;
  bottom: 30px;
  right: 0;
  background-color: transparent;

  img {
    border: none;
    margin: 0;
    width: 100%;
    height: 100%;
  }
}
