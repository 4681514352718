.step3 {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: calc(100% - 136px);
  overflow: auto;
}

.step3__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 43px;
  box-sizing: border-box;
  padding: 0 30px;
  overflow: auto;
  margin: 17px 0;
}

.step3__title {
  color: black;
  font-size: 18px;
  font-family: Heebo;
  font-weight: 300;
  margin-bottom: 29px;
}

.step3__form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.step3__form-inputWrap {
  display: flex;
  gap: 24px;
}

.step3__description {
  color: black;
  font-size: 18px;
  font-family: Heebo;
  font-weight: 500;
  margin-top: 30px;
}

.step3__logged {
  width: 100%;
}

.step3__logged-title {
  color: black;
  font-size: 18px;
  font-family: Heebo;
  font-weight: 300;
}

.step3__logged-userInfo {
  margin: 40px 0 45px 0;
}

.step3__logged-userInfo-value {
  color: black;
  font-size: 18px;
  font-family: Heebo;
  font-weight: 700;
  display: flex;
}

.step3__logged-userInfo-value-sec {
  font-weight: 300;
}

@media screen and (max-width: 436px) {
  .step3__content {
    padding: 0 17px;
  }
}
