.custom-simple-bar{

    width: 100%;

    overflow: auto;

    &::-webkit-scrollbar {
        width: 7px;
        height: 7px;
      }
      /* Track */
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #61c38b;
      }
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #61c38b;
      }
}