.steps {
  display: flex;
  gap: 164px;
  //align-items: center;
  position: relative;
  margin-top: 11px;

  @media (min-width: 1440px) and (max-width: 1600px) {
    gap: calc(124px + (164 - 124) * ((100vw - 1440px) / (1600 - 1440)));
  }
  @media (min-width: 1180px) and (max-width: 1440px) {
    gap: calc(84px + (124 - 84) * ((100vw - 1180px) / (1440 - 1180)));
  }

  @media (min-width: 1024px) and (max-width: 1180px) {
    margin-right: 50px;
    gap: calc(64px + (64 - 44) * ((100vw - 1024px) / (1180 - 1024)));
  }

  &__item {
    color: white;
    font-size: 32px;
    font-family: Heebo;
    font-weight: 500;
    border: 1px solid #d8d8d8;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 44px;
    height: 44px;
    box-sizing: border-box;
    background: #d8d8d8;
    user-select: none;
    //pointer-events: none;

    &-count {
    }
    &-name {
      position: absolute;
      top: calc(100% + 2px);
      color: black;
      font-size: 18px;
      font-family: Heebo;
      font-weight: 500;
    }
    .activeName {
      color: #61c38b;
    }
  }

  .itemOpen {
    color: black;
    border: 1px solid black;
    background: white;
    cursor: pointer;
  }
  .itemActive {
    color: white;
    border: 1px solid #61c38b;
    background: #61c38b;
  }
  &__line {
    position: absolute;
    top: 22px;
    width: 100%;
    height: 1px;
    background: black;
  }
}

@media screen and (max-width: 1024px) {
  .steps {
    display: none;
  }
}
