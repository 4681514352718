.popUpDel {
  position: absolute;
  left: calc(100% + 24px);
  width: 491px;
  top: calc(100% - 44px);

  &__triangle {
    width: 0;
    height: 0;
    border-top: 25.5px solid transparent;
    border-bottom: 25.5px solid transparent;
    border-right: 24px solid #FFE0E0;
    font-size: 0;
    position: absolute;
    left: -23px;
    top: 0;
  }
  &__titleWrap {
    background-color: #FFE0E0;
    height: 48px;
    box-sizing: border-box;
    padding-left: 11px;
    display: flex;
    align-items: center;
    &-title {
      color: black;
      font-size: 20px;
      font-family: Heebo;
      font-weight: 500;
    }
  }
  &__info {
    border: 2px #FFE0E0 solid;
    box-sizing: border-box;
    padding: 24px 18px 30px 30px;
    background: white;
    &-desc {
      color: black;
      font-size: 16px;
      font-family: Heebo;
      font-weight: 400;
    }
    &-elementsWrap {

    }
    &-btnWrap {
      margin-top: 16px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      &-btn {
        color: white;
        font-size: 20px;
        font-family: Heebo;
        font-weight: 500;
        width: 173px;
        height: 47px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #CD3333;
        outline: none;
        border: none;
        cursor: pointer;
      }
    }
  }
}
