.step2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  height: calc(100% - 136px);
}

.step2__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 13px;
  margin: 17px 0;
}

.step2__notif2 {
  margin-top: 15px;
}

.step2__notif2-wrap {
  background: #ecb1b1;
  padding: 10px;
}

.step2__line {
  width: 195px;
  background: #bbb6b6;
  height: 2px;
  margin: 30px auto 20px auto;
}

.step2__notif {
  background: #ecb1b1;
  padding: 20px 18px;
  box-sizing: border-box;
  margin-top: 33px;
}

.step2__notif-main {
  color: black;
  font-size: 18px;
  font-family: Heebo;
  font-weight: 500;
  margin-bottom: 12px;
}

.step2__notif-secondary {
  color: black;
  font-size: 18px;
  font-family: Heebo;
  font-weight: 400;
}

.step2__notif-secondary-textWrap {
  display: flex;
}

.step2__notif-secondary-dot {
  width: 5px;
  height: 5px;
  background: black;
  border-radius: 50%;
  margin-top: 10px;
  margin-right: 10px;
}

@media screen and (max-width: 436px) {
  .step2__content {
    padding: 0 10px 0 0;
  }
}
