.account-modal {
  max-width: 1287px;
  // min-height: 495px;
  width: calc(100% - 32px);
  position: relative;
  text-align: left;
  display: flex;
  flex-direction: column;

  &__nav {
    border-bottom: 2px solid var(--main-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 19px;

    &-list {
      display: flex;
      align-items: center;

      [class*='__item'] {
        &:not(:last-child) {
          margin-right: 10px;
        }

        color: black;

        &.active {
          background: var(--main-color);
          color: white;
        }
      }

      &__item {
        font-size: 20px;
        font-weight: 500;
        padding: 5px 25px;
        border-radius: 10px 10px 0px 0px;
      }
    }
  }

  &__body {
    background: #ffffff;
    padding: 35px 47px 47px;
  }

  &__content {
    margin-top: 28px;
    height: 100%;
    // min-height: 510px;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}

.can-sort span {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:after {
    content: '';
    display: block;
    width: 8px;
    height: 12px;
    margin-left: 10px;
    background: url('../../../assets/filter.svg');
  }
}

.wrapper-logout-mob {
  width: 100%;
  color: black;
  display: none;

  justify-content: flex-end;
  margin-bottom: 10px;
}

.logout-mob {
  border: 3px solid black;
  padding: 5px;
  color: black;
}

@media screen and (max-width: 1024px) {
  .account-modal {
    &__nav {
      padding: 0;
      &-list {
        // [class*='__item'] {
        //   &.active {
        //     display: none;
        //   }
        // }
        &__item {
          padding: 5px 5px;
          font-size: 18px;
        }
      }
    }
  }

  .logout {
    display: none;
  }
  .wrapper-logout-mob {
    display: flex;
  }
}

@media screen and (max-width: 436px) {
  .account-modal {
    &__body {
      padding: 15px;
    }
    &__nav {
      &-list {
        &__item {
          font-size: 16px;
        }
      }
    }
  }
}
