.wrapper-search-product {
  width: calc(100% - 42px);
  max-width: 1057px;
  position: relative;

  pointer-events: auto;

  ::-webkit-scrollbar {
    width: 7px;
    height: 0px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #61c38b;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #61c38b;
  }
}

.container-search-product {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px;
}

// .close-modal-none {
  // display: none;
// }

.title-content-search-product {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
}

.btn-search-product {
  background-color: #61c38b;
  color: white;
  width: 260px;
  height: 60px;
  box-sizing: border-box;
}

.container-options-search-product {
  display: flex;
  flex-wrap: wrap;
  width: 568px;
  padding: 12px 0;
}

.item-option-search-product {
  margin: 12px;
  width: 260px;
  height: 192px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid #d8d8d8;
  box-sizing: border-box;
  cursor: pointer;
  user-select: none;
  flex-direction: column;

  &:hover {
    border-color: #61c38b;
  }
  &.active {
    border-color: #61c38b;
  }
}

.img-item-option-search-product {
  width: 100px;
  height: 100px;
  margin: 12px 0;
}

.title-item-option-search-product {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}

.container-info-search-product {
  width: 100%;
  height: 300px;
  overflow: scroll;
}

.item-info-search-product {
  margin-bottom: 35px;

  &:last-child {
    margin-bottom: 0px;
  }
}

.title-item-info-search-product {
  padding-left: 20px;
  margin-bottom: 6px;
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0.05em;
}

.options-items-info-search-product {
}

.option-item-info-search-product {
  height: 40px;
  display: flex;
  align-items: center;
  &:nth-child(odd) {
    width: calc(200px + 325px + 325px);
    background: rgba(0, 0, 0, 0.1);
  }
}

.select-img-product-search {
  display: flex;
  margin-top: 10px;
  width: 100%;
}

.container-img-product-search {
  position: relative;
  padding: 10px;
}

.buffer-img-product-search {
  width: 200px;
}

.recommendation-search-product {
  position: absolute;
  left: 5px;
  top: 5px;
}

.title-container-img-product-search {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  text-align: center;
  letter-spacing: 0.05em;

  color: #000000;
}

.name-item-info-search-product {
  padding-left: 40px;
  width: 200px;
  box-sizing: border-box;
}

.star-item-info-search-product {
  position: relative;
  margin: 0 0 0 15px;
  width: 310px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    padding: 0 2.5px;
  }
}

.select-btns-product-search {
  display: flex;
  margin-top: 10px;
  width: 100%;
}

.container-btn-product-search {
  position: relative;
  padding: 10px;
}

.buffer-btns-product-search {
  width: 200px;
}

.btn-konfigurieren-product {
  background-color: #61c38b;
  color: white;
  width: 310px;
  height: 60px;
  box-sizing: border-box;
}

.bg-gray-product-search {
  position: absolute;
  z-index: -1;
  width: calc(100% - 20px);
  height: calc(300px + 125px + 163px);
  background: #e0e0e0;
}
