.buttonOpt2 {
  width: 100%;
  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 20px;
  font-family: Heebo;
  font-weight: 500;
  background: #61c38b;
  border: 2px #61c38b solid;
  box-sizing: border-box;
  gap: 14px;
  cursor: pointer;
  outline: none;
}

.opt2 {
  border: 1px black solid;
  color: black;
  background: white;
  flex-direction: row-reverse;
}
.opt3 {
  flex-direction: row-reverse;
}
