.wrapper-select-product {
  width: calc(100% - 42px);
  max-width: 1080px;
  position: relative;
}

.container-select-product {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 40px;
}

.close-modal-none {
  display: none;
}

.container-upper-select-product {
  display: flex;
  justify-content: space-between;
}

.title-content-select-product {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
}

.subtitle-content-select-product {
}

.description-content-select-product {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;
  max-width: 450px;
  margin-top: 28px;
}
.btn-upper-select-product {
  user-select: none;
  width: 260px;
  height: 60px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid black;
  cursor: pointer;
}
.items-container-select-product {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.item-select-product {
  // width: 440px;
  // height: 290px;
  width: 240px;
  height: 180px;
  box-sizing: border-box;
  border: 3px solid #a6a6a6;
  &:hover {
    border-color: #61c38b;
  }

  &.active {
    border-color: #61c38b;
  }

  img {
    height: 90%;
  }
}

.title-item-select-product {
  margin-top: 12px;
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  text-align: center;
}
.container-btnStart-select-product {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.btnStart-select-product {
  background-color: #61c38b;
  color: white;
  width: 260px;
  height: 60px;
  box-sizing: border-box;
}
