.quotes-filter {
  margin-bottom: 27px;

  &__row {
    display: flex;
    align-items: center;
    grid-gap: 40px;
  }

  &__col {
    width: 50%;
  }

  &__search {
    display: flex;
    align-items: center;
    grid-gap: 13px;
    max-width: 553px;

    button {
      max-width: 132px;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    grid-gap: 30px;

    p {
      font-size: 16px;
      font-weight: 500;
    }
  }
}
@media screen and (max-width: 1024px) {
  .quotes-filter {
    display: none;
  }
}
