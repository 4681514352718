:root {
  --main-color: #61c38b;
  --border-color: #bbb6b6;

  // notification
  --toastify-toast-min-height: 50;
}

#root,
body,
html {
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
}

.hidden {
  transition: 1s;
  opacity: 0;
  pointer-events: none !important;
}

.hidden2 {
  z-index: -1 !important;
}

:fullscreen,
::backdrop {
  background-color: rgba(255, 255, 255, 0);
}

.disable {
  opacity: 0.4 !important;
  cursor: default !important;
  user-select: none !important;
  pointer-events: none !important;
}

body {
  background-image: url(./assets/thermogreen-bg.png),
    linear-gradient(180deg, #f3f3f3 50%, #bbb6b6);
  background-repeat: repeat;
  font-family: 'Heebo', sans-serif;
}

.accent-color {
  color: var(--main-color);
}

.container-threejs {
  position: absolute;
  right: 0;
  width: calc(100% - 436px);
  height: 100%;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  &.fullscreen {
    width: 100%;
    height: 100%;
  }
}

.threejsExpertMode {
  height: calc(100% - 98px);
  bottom: 0;
}

.wrapper-accordion {
  width: 100%;
  display: none;
  align-items: flex-end;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;

  &.active {
    display: flex;
  }
}

.container-layout-content {
  position: absolute;
  width: 436px;
  background-color: white;
  bottom: 0;
  left: 0;
  height: 425px;
  pointer-events: auto;
  padding: 20px;
  box-sizing: border-box;
}

.title-item {
  font-size: 24px;
}

.body-item {
  margin-top: 10px;
  display: flex;
}

.option-item {
  margin-right: 10px;
  width: 24px;
  height: 24px;
  background-color: red;
  cursor: pointer;
  user-select: none;
}

.container-undo-redo {
  position: absolute;
  bottom: 26px;
  right: 37px;
  display: flex;
  svg {
    margin-left: 38px;
    cursor: pointer;
    user-select: none;
  }
}

.container-controls {
  position: absolute;
  top: 122px;
  right: 31px;
  display: flex;
  flex-direction: column;
  gap: 35px;

  svg {
    outline: none;
    cursor: pointer;
    user-select: none;
  }
}

.container-mob {
  position: absolute;
  display: none;
  gap: 35px;

  &.logo {
    top: 15px;
    left: 17px;
  }

  &.top {
    top: 15px;
    right: 15px;
  }

  &.bottom {
    bottom: calc(50% + 15px);
    right: 15px;
  }

  svg {
    outline: none;
    cursor: pointer;
    user-select: none;
  }
}

.container-fullscreen-controls {
  position: absolute;
  top: 26px;
  right: 37px;
  display: flex;
  svg {
    outline: none;
    margin-left: 38px;
    cursor: pointer;
    user-select: none;
  }
}
.container-fullscreen-help {
  position: absolute;
  top: 26px;
  right: 37px;
  display: flex;
  svg {
    outline: none;
    margin-left: 38px;
    cursor: pointer;
    user-select: none;
  }
}

.expModeBtn {
  width: 50px;
  height: 50px;
  background: white;
  cursor: pointer;
  margin-left: 38px;
  border: none;
  border-radius: 50%;
}

.container-note-exit-fullscreen {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  position: absolute;
  bottom: 30px;
  width: 100%;
  padding: 0 21px;
  box-sizing: border-box;
  text-align: center;
}

.element-menu {
  display: flex;
  transition: 0.1s;
  div {
    margin: 5px;
    padding: 5px;
    cursor: pointer;
    user-select: none;
    color: white;
    background-color: brown;
  }
}

.exportModel {
  border: 1px solid black;
  margin-left: 5px;
  background-color: white;
  padding: 0 15px;
}

.wrapper-video-tutorial {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: white;
  width: 370px;

  box-sizing: border-box;
  border-radius: 20px;
  border: 2px gray solid;

  &.active {
    width: calc(100% - 40px);
    height: calc(100% - 40px);
  }
}

.container-video-tutorial {
  width: 100%;
  height: 100%;
  padding: 20px;
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: gray;
  box-sizing: border-box;
}

.title-video-tutorial {
  padding-bottom: 10px;
}

.close-video-tutorial {
  width: 25px; /* Установите размер кнопки по вашему усмотрению */
  height: 25px;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;

  background-color: #fff;
  border-radius: 50%;
  border: 1px solid gray;
  box-sizing: border-box;

  &:hover {
    background-color: #61c38b;
  }
}

.cross-video-tutorial {
  position: absolute;
  width: 60%;
  height: 1px;
  top: 49%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background-color: #fff;
}

.line-video-tutorial {
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: gray;
}

.line1 {
  transform: rotate(90deg);
}

.play-video-tutorial {
  pointer-events: none;
  width: 50px;
  height: 50px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -20%);
  box-sizing: border-box;
  cursor: pointer;
  border: 1px solid gray;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15px 0 15px 30px;
    border-color: transparent transparent transparent #61c38b;
    top: 50%;
    left: 56%;
    transform: translate(-50%, -50%);
  }
}

.video-tutorial {
  height: calc(100% - 40px - 20px);
  display: flex;
  align-items: center;
  background-color: black;

  video {
    max-height: 100%;
    background-color: black;
  }
}

.minimize-button {
  width: 25px;
  height: 25px;

  background-color: #fff;
  border-radius: 50%;
  border: 1px solid gray;
  box-sizing: border-box;

  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 10px;
  top: 10px;

  &:hover {
    background-color: #61c38b;
  }
}

.minimize-button img {
  width: 16px;
  height: 16px;
  transform: scaleY(-1);
}

.simplebar-scrollbar.simplebar-visible:before {
  opacity: 1 !important;
}

.simplebar-scrollbar:before {
  opacity: 1 !important;
}

.simplebar-scrollbar:before {
  background: #61c38b !important;
  border-radius: 0px;
}

.simplebar-scrollable-y {
  height: 100dvh !important;
}
.simplebar-track {
  &.custom-zindex {
    z-index: -1 !important;
  }
}

@media screen and (max-width: 1024px) {
  .container-controls {
    display: none;
  }
  .container-mob {
    display: flex;
  }
  .parent-container-threejs {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .container-threejs {
    width: 100%;
    height: 50%;
    top: 0;
    left: 0;

    &.fullscreen {
      width: 100%;
      height: 100%;
    }
  }
}

@media screen and (max-width: 436px) {
  .container-mob {
    gap: 28px;
    &.logo {
      top: 15px;
      left: 17px;

      img {
        width: 123px;
      }
    }
    svg {
      width: 30px;
      height: 30px;
    }
  }

  .container-fullscreen-controls {
    right: 15px;
    top: 15px;
    svg {
      width: 30px;
      height: 30px;
    }
  }
}


.wrapper-camera-perspective-input {
  display: flex;
  align-items: center;

  width: 250px;


  input {
    -webkit-appearance: none;
    width: 100%; 
    height: 4px; 
    background: #e0e0e0;
    outline: none;
    border-radius: 2px; 
    opacity: 0.9;
    transition: opacity 0.2s ease;

    &:hover{
      opacity: 1;
    }
  }

  
  input::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 16px; 
    height: 16px; 
    background: #ccc;
    border-radius: 50%; 
    cursor: pointer;
  
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); 
  }
  
  input::-moz-range-thumb {
    width: 16px;
    height: 16px;
    background: #ccc;
    border-radius: 50%;
    cursor: pointer;
    border: 2px solid #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }
  
  input::-ms-thumb {
    width: 16px;
    height: 16px;
    background: #ccc;
    border-radius: 50%;
    cursor: pointer;
    border: 2px solid #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }
  
}