.sidePanelFooter {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-top: 1px #b4b4b4 solid;
  position: absolute;
  bottom: 0;

  &__info {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px 10px 29px;
    box-sizing: border-box;
    border-bottom: 1px #b4b4b4 solid;
    background-color: white;

    &-text {
      color: black;
      font-size: 20px;
      font-family: Heebo;
      font-weight: 500;
      width: 100%;

      &.end {
        text-align: end;
      }
    }
    .infoTextBlur {
      display: flex;
      justify-content: flex-end;
      filter: blur(3px);
    }
    &-iconWrap {
      position: relative;
      cursor: pointer;
      padding-left: 15px;
    }
  }
  .anPadd {
    padding: 10px 29px 10px 29px;
  }
  &__btnFooter {
    padding: 18px;
    background-color: white;
    &-btnWrap {
      display: flex;
      gap: 32px;
    }
  }
}
