.steps-mob {
  display: none;
  position: relative;
  width: 100%;
  max-width: 370px;
  justify-content: space-between;

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    border: none;
    padding: 0;

    &-count {
      color: white;
      font-size: 15px;
      font-family: Heebo;
      font-weight: 500;
      border: 1px solid #d8d8d8;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 25px;
      height: 25px;
      box-sizing: border-box;
      background: #d8d8d8;
      user-select: none;
      pointer-events: none;

      .itemActive & {
        border: 1px solid #61c38b;
        background: #61c38b;
        width: 34px;
        height: 34px;
      }

      .itemOpen & {
        color: black;
        border: 1px solid black;
        background: white;
        cursor: pointer;
      }
    }

    &-name {
      display: none;
      padding-left: 10px;
      color: black;
      font-size: 18px;
      font-family: Heebo;
      font-weight: 500;

      .itemActive & {
        display: flex;
      }
    }

    .itemOpen &.steps-mob__item-count {
      color: black;
      border: 1px solid black;
      background: white;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 1024px) {
  .steps-mob {
    display: flex;
  }
}
