.wrapper-verglasung-options {
  width: calc(100% - 42px);
  max-width: 695px;
  position: relative;
}

.container-verglasung-options {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 30px;
}
.section-verglasung-options {
  margin-bottom: 22px;
}

.title-section-verglasung-options {
  margin-bottom: 22px;
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  svg {
    margin-left: 5px;
  }
}

.content-section-verglasung-options {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.input-section-verglasung-options {
  width: calc(100% - 2px);
  padding-bottom: 250px;
  input[type='range'] {
    width: 100%;
    -webkit-appearance: none;
    height: 5px;
    background: #d9d9d9;
    border-radius: 5px;
    background-image: linear-gradient(#61c38b, #61c38b);
    background-repeat: no-repeat;
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background-color: white;
    border: 3px solid #61c38b;
    cursor: pointer;
    box-shadow: 0 0 2px 0 #555;
    transition: background 0.3s ease-in-out;
  }

  input[type='range']::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }
}

.titles-input-verglasung-options {
  width: 100%;
  display: flex;
  justify-content: space-between;

  margin-top: 10px;

  //   margin-left: 5px;

  span {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.input-verglasung-options-value-w {
  color: white;

  &.active {
    color: black;
  }
}

.input-verglasung-options-value-g {
  color: #d8d8d8;

  &.active {
    color: black;
  }
}

.verglasung-options-img {
  width: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-right: 42px;
  margin-top: 10px;
}

.verglasung-options-title {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.verglasung-options-content {
  width: 100%;
  height: 75px;
  border: 3px solid #d8d8d8;
  box-sizing: border-box;
  &:hover {
    border-color: #61c38b;
  }
}

.btn-verglasung-options {
  background-color: #61c38b;
  color: white;
  width: 185px;
  height: 60px;
  position: absolute;
  right: 30px;
  bottom: 30px;
}

@media screen and (max-width: 436px) {
  .container-verglasung-options {
    padding: 17px;
  }
  .btn-verglasung-options {
    right: 17px;
    bottom: 17px;
  }
}
