.app-select {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__wrapper {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    max-width: 198px;
    width: 100%;

    .app-select__label {
      font-weight: bold;
    }
  }

  &__indicator {
    display: none!important;

    &-separator {
      display: none!important;
    }
  }

  &__indicators {
    position: relative;
    width: 12px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center!important;
    padding: 0;

    svg {
      display: none;
    }

    &:before {
      content: '';
      display: block;
      width: 12px;
      height: 15px;
      position: absolute;
      background: url('../../../assets/select-icon.svg') no-repeat center/contain;
    }
  }

  &__control {
    display: flex!important;
    align-items: center!important;
    min-height: auto!important;
    border: none!important;
    max-width: 198px!important;
    width: 100%!important;
    background: #D9D9D9!important;
    border-radius: 10px!important;
    padding-right: 5px;
    box-shadow: none!important;
    font-size: 16px;
    font-weight: 500;
  }

  &__value-container {
    padding: 3px 8px!important;
    width: 100%;
  }

  &__placeholder {
    color: white!important;
  }

  &__single-value {
    color: black!important;
  }

  &__input {
    color: black!important;

    &-container {
      border: none!important;
      font-size: 0.8rem;
      width: 100%;
      min-height: auto!important;
      padding: 0!important;
    }
  }

  &__menu {
    min-width: 150px;
  }

  &__option {

    &:hover {
      background: #E3E3E3!important;
    }

    &--is-focused {
      background: #E3E3E3!important;
    }

    &--is-selected {
      background: var(--main-color)!important;
    }
  }
}
