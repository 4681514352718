.header {
  background: #ffffff;
  display: flex;
  //align-items: center;
  height: 98px;
  padding: 0 23px 0 30px;
  justify-content: space-between;
  box-sizing: border-box;
  border-bottom: 1px #bbb6b6 solid;
  flex-direction: column;
  width: 100%;
  z-index: 10;

  &__logoWrap {
    display: flex;
    align-items: center;
  }
  &__controls {
    display: flex;
    align-items: flex-end;
    gap: 14px;

    &-switchWrap {
      display: flex;
      flex-direction: column;
      gap: 11px;
      align-items: center;
      margin-bottom: 14px;
      border: none;
      outline: none;
      background: none;
      &-switch {
        border-radius: 20px;
        border: 2px black solid;
        width: 37px;
        height: 23px;
        padding: 0 2px;
        box-sizing: border-box;
        justify-content: end;
        display: flex;
        align-items: center;
        cursor: pointer;

        &-indicator {
          width: 17px;
          height: 17px;
          border-radius: 50%;
          background: #61c38b;
        }
      }
      &-name {
        color: black;
        font-size: 12px;
        font-family: Heebo;
        font-weight: 400;
      }
    }
    &-loginBtn {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 10px;
      margin-bottom: 14px;
      cursor: pointer;
      border: none;
      background: none;

      &-name {
        color: black;
        font-size: 12px;
        font-family: Heebo;
        font-weight: 400;
      }
    }
  }
}

.header__content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.header-steps-mob {
  justify-content: center;
  display: none;
  height: 59px;
  background-color: white;
}

@media screen and (max-width: 1024px) {
  .header-steps-mob {
    display: flex;
  }

  .header__content {
    height: 58px;
    align-items: center;
    width: 100%;
  }

  .header__controls-switchWrap {
    display: none;
  }

  .header__controls-loginBtn {
    background-color: white;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    margin: 0;
    div {
      display: none;
    }
  }

  .header {
    width: 100%;
    height: calc(58px + 60px);

    &.none {
      height: 60px;

      .header__content {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 436px) {
  .header__logoWrap {
    img {
      width: 123px;
    }
  }

  .header {
    padding: 0 17px;
  }

  .header__controls-loginBtn {
    img {
      width: 12px;
      height: 12px;
    }
  }
}
