.wrapper-solar-modal {
  width: calc(100% - 42px);
  max-width: 695px;
  position: relative;
}

.container-solar-modal {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 30px;
}

.container-btnStart-solar-modal {
  margin-top: 100px;
  display: flex;
  justify-content: end;
  text-transform: uppercase;
}

.btn-solar-modal {
  color: white;
  width: 185px;
  height: 60px;
  margin-left: 5px;
  text-transform: uppercase;
}

.section-solar-modal {
  margin-bottom: 22px;
}
.title-section-solar-modal {
  margin-bottom: 22px;
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  svg {
    margin-left: 5px;
  }
}
.content-section-solar-modal {
  display: flex;
  flex-wrap: wrap;
}
.solar-modal-options-img {
  width: 171px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-right: 38px;
  margin-top: 10px;
}
.solar-modal-options-content {
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #d8d8d8;
  box-sizing: border-box;
  &:hover {
    border-color: #61c38b;
  }
  &.active {
    border-color: #61c38b;
  }
  img {
    height: 100%;
  }
}

.solar-modal-options-title {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 527px) {
  .content-section-solar-modal {
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .solar-modal-options-img {
    margin-right: 0;
  }
  .container-btnStart-solar-modal {
    justify-content: space-between;
  }
}

@media screen and (max-width: 436px) {
  .container-solar-modal {
    padding: 17px;
  }

  .container-btnStart-solar-modal {
    margin-top: 20px;
  }
}
