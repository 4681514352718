.wrapper-plug-rotation {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  z-index: 1100;
}

.content-plug {
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  //   &::-webkit-scrollbar {
  //     width: 7px;
  //     height: 0px;
  //   }

  //   /* Track */
  //   &::-webkit-scrollbar-track {
  //     background-color: transparent;
  //   }

  //   /* Handle */
  //   &::-webkit-scrollbar-thumb {
  //     background: #61c38b;
  //   }

  //   /* Handle on hover */
  //   &::-webkit-scrollbar-thumb:hover {
  //     background: #61c38b;
  //   }
}

.container-img-plug {
  position: relative;
  display: flex;
  justify-content: center;
  height: 354px;
  overflow: hidden;
}

.content-bg-plug {
  position: absolute;
  top: 0;
}

.content-logo-plug {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 30px;
  z-index: 2;
}

.content-title-plug {
  width: 100%;
  display: flex;
  justify-content: center;

  text-align: center;

  position: absolute;
  bottom: 19px;

  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  letter-spacing: 0.05em;
  color: #000000;
}

.container-description-plug {
  margin-top: 19px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.content-description-plug {
  width: 331px;

  font-family: 'Heebo';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  /* or 162% */

  color: #000000;
}

.container-devices-plug {
  margin-top: 24px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.content-devices-plug {
  width: 320px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.center {
    padding-top: 20px;
    justify-content: center;
  }
}

.container-info-plug {
  margin-top: 24px;
  width: 100%;
  display: flex;
  padding-bottom: 30px;
  justify-content: center;
}

.content-info-plug {
  width: 331px;
  box-sizing: border-box;
  background: #ffe0e0;
  border-radius: 5px;
  padding: 18px 10px 21px 24px;

  font-family: 'Heebo';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

@media (orientation: landscape) {
  .wrapper-plug-rotation {
    display: flex;
  }
}
