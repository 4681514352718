.wrapper-popup-info {
  position: fixed;

  width: 490px;
  filter: drop-shadow(6px 6px 20px rgba(0, 0, 0, 0.1));
  border: 2px solid #61c38b;
  box-sizing: border-box;

  margin-top: -10px;
  margin-left: 53px;
  z-index: 10000;

  &.img {
    margin-top: 25px;
    margin-left: 125px;
  }
}

.triangle {
  width: 0;
  height: 0;
  border-top: 21.5px solid transparent;
  border-bottom: 21.5px solid transparent;
  border-right: 20px solid #61c38b;
  font-size: 0;
  position: absolute;
  left: -22px;
  top: -2px;
}

.header-popup-info {
  background-color: #61c38b;
  height: 40px;
  box-sizing: border-box;
}

.title-popup-info {
  padding: 8px 24px;
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;

  color: #ffffff;
}

.body-popup-info {
  background: rgba(255, 255, 255, 0.9);
  padding: 24px;
}

.description-popup-info {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.link-popup-info {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #0000ff;
}
