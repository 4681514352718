.app-input {
  display: flex;
  width: 100%;

  input {
    box-sizing: border-box;
    border: 1px solid #eeeeee;
    background: #eeeeee;
    color: #000000;
    display: block;
    width: 100%;
    outline: none;
    font-weight: 500;
    font-size: 20px;
    padding: 18px 25px;

    &::placeholder {
      color: #666666;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
.opt2 {
  input {
    // border: 1px black solid;
    font-size: 18px;

    &::placeholder {
      color: black;
    }
  }
}
