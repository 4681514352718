.login-modal {
  background: #e3e3e3 !important;
  max-width: 509px;
  width: calc(100% - 42px);
  position: relative;
  padding: 0 !important;

  &__content {
    background: #ffffff;
    padding: 45px;

    p {
      font-weight: 500;
      font-size: 20px;
      line-height: 29px;
    }
  }

  &__input {
    margin-bottom: 25px;
    display: flex;
  }

  &__submit {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      font-size: 18px;
    }
  }

  &__footer {
    font-size: 18px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 28px;
    padding-right: 20px;
    margin-top: 74px;
    border-top: 1px solid var(--border-color);

    span {
      max-width: 286px;
    }
  }

  &__btn {
    margin-left: 10px;
    max-width: 164px;
  }
}

@media screen and (max-width: 436px) {
  .login-modal {
    &__content {
      p {
        margin-top: 0;
      }
      padding: 17px;
    }
    &__footer {
      margin-top: 10px;
    }
  }
}
