.step1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 37px;
  box-sizing: border-box;
  height: calc(100% - 136px);
}

.step1_1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 37px;
  box-sizing: border-box;
  height: calc(100% - 136px);
}

.step1__text {
  color: black;
  font-size: 18px;
  font-family: Heebo;
  font-weight: 300;
}

.step1__line {
  display: flex;
  align-items: center;
  justify-content: center;
}

.step1__line span {
  width: 195px;
  border-bottom: 2px solid #bbb6b6;
}

.step1__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 43px;
  box-sizing: border-box;
  padding-right: 34px;
  padding-left: 40px;
  overflow: auto;
  margin: 17px 0;
}

.step1_1__content {
  width: 100%;
  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  padding-right: 34px;
  padding-left: 40px;
  overflow: auto;
  margin: 17px 0;
}

.items-lamellendach {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.item-lamellendach {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  img {
    border: 3px solid white;
    width: calc(100% - 6px);
    cursor: pointer;
    user-select: none;
    max-width: 316px;
  }

  &.active {
    img {
      border-color: #61c38b;
    }
  }
}

.title-item-lamellendach {
  font-family: Heebo;
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  text-align: center;
  text-transform: uppercase;
  padding-top: 14px;
  padding-bottom: 14px;
}

.list-item-lamellendach {
  font-family: Heebo;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;

  ul {
    margin: 0;
    li span {
      font-weight: 600;
    }
  }
  margin-bottom: 34px;
}

.step1__content-choiceModel {
  width: 100%;
  height: 162px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10px;
  border: 2px black solid;
  cursor: pointer;
  box-sizing: border-box;
  outline: none;
  background: white;
}

.step1__content-choiceModel div {
  text-decoration: none;
  color: black;
}

.step1__content-choiceModel-image {
  height: 127px;
}

.step1__content-choiceModel-title {
  display: flex;
  align-items: center;
  gap: 5px;
}

.choiceModelActive {
  border: 2px #61c38b solid;
  cursor: default;
}

@media screen and (max-width: 1024px) {
  .step1 .buttonOpt2 {
    display: none;
  }
}

@media screen and (max-width: 436px) {
  .step1__content {
    padding: 0 17px;
  }
  .step1_1__content {
    padding: 0 17px;
  }
}
