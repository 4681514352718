.projects-table {
  width: 100%;
  height: 100%;

  overflow: auto;

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;

    min-width: 800px;
    margin-bottom: 10px;
    * {
      box-sizing: border-box;
    }

    thead {
      tr {
        th {
          padding: 5px 10px;
          color: #717171;
          font-weight: 500;
          font-size: 21px;
          line-height: 31px;

          &:nth-child(2) {
            text-align: center;
          }

          &:last-child {
            max-width: 20%;
            text-align: right;
          }
        }
      }
    }

    tbody {
      tr {
        &:nth-child(2n + 1) {
          background: #f4f4f4;
        }

        td {
          padding: 5px 10px;

          &:nth-child(2) {
            width: 20%;
            text-align: center;
          }

          &:last-child {
            text-align: right;
            padding-right: 0;
          }
        }
      }
    }
  }

  &__actions {
    display: inline-flex;
    grid-gap: 10px;
  }

  &__pagination {
    margin: 20px 0 0 auto;
    max-width: fit-content;

    button {
      background: none;
      border: none;
      color: #222222;
      margin: 0 3px;
      cursor: pointer;

      &:disabled {
        opacity: 0.3;
        cursor: not-allowed;
      }
    }
  }
}
