.container-accordion {
  width: 436px;
  pointer-events: none;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

//HEADER

.header-accordion {
  pointer-events: auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  width: 100%;
  border-bottom: 1px solid #d8d8d8;
  background: linear-gradient(180deg, #fbfbfb 0%, #ffffff 100%);

  height: 116px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;

  padding: 0 30px;

  svg {
    margin-left: 15px;
    // width: 28px;
    // height: 28px;
  }
}

.item-header-accordion {
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.person-img {
  padding-top: 10px;
  width: 24px;
  height: 28px;
}
// BODY

.body-accordion {
  pointer-events: auto;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  // height: calc(100% - 116px - 69px);

  ::-webkit-scrollbar {
    width: 7px;
    height: 0px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #61c38b;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #61c38b;
  }
}

.line-or-accordion {
  border: 1px solid #cdcdcd;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 195px;
  margin: 40px auto 20px;
}

.accordion-section-title {
  text-decoration: none;
  color: black;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0 30px;
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  border-bottom: 1px solid #d8d8d8;
  &.active {
    background: #ececec;
    color: #61c38b;
  }
}

.wrapper-accordion-section-content {
  position: relative;
}

.accordion-section-content,
.accordion-section-content-price {
  justify-content: space-between;
  font-family: 'Heebo';
  font-style: normal;
  font-size: 17px;
  display: none;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  flex-direction: column;
  position: relative;
  &.active {
    display: flex;
    overflow: scroll;
    border-bottom: 1px solid #d8d8d8;
    height: 100%;
    // height: fit-content;

    max-height: calc(100vh - 116px - 264px - 70px - 60px);
    padding-bottom: 32px;
  }
}

.accordion-item,
.accordion-item-price {
  padding: 0 27px;
  margin-top: 24px;
  // padding-bottom: 24px;
  position: relative;

  &.text {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    width: 299px;
    margin: 0 auto;
  }
}

.accordion-item-scroll-down {
  // background-color: black;
  color: #61c38b;
  display: flex;
  // display: none;
  align-items: center;
  justify-content: center;
  height: 35px;
  position: absolute;
  // left: 50%;
  // transform: translateX(-50%);
  cursor: pointer;
  user-select: none;
  width: calc(100% - 7px);

  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 44.79%,
    #ffffff 100%
  );

  bottom: 1px;

  svg {
    margin-right: 3px;
  }
}

.accordion-item-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  position: relative;
  svg {
    margin-left: 10px;
  }
  span {
    user-select: none;
    span {
      color: #61c38b;
      padding-left: 5px;
      cursor: pointer;
    }
  }
}

.accordion-item-content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 12px;

  &.not-visible {
    display: none;
  }

  &.color-option {
    justify-content: center;
    padding: 10px 0;
  }

  &.column {
    flex-direction: column;
  }
}

.list-item-color {
  width: calc(100% - 39px);
  display: flex;
  flex-wrap: wrap;
}

.accordion-option-img {
  width: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-right: 12.5px;
  margin-left: 12.5px;
  margin-top: 10px;
}

.accordion-option-title {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.accordion-option-content {
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  height: 75px;
  border: 3px solid #d8d8d8;
  box-sizing: border-box;
  &:hover {
    border-color: #61c38b;
  }
  &.active {
    border-color: #61c38b;
  }
}

.accordion-option-img {
  &.disable {
    .accordion-option-content {
      &:hover {
        border-color: #d8d8d8 !important;
      }
    }
  }
}

.accordion-option-color {
  width: 60px;
  height: 60px;
  margin: 0 12px;
  margin-top: 12px;
  border-radius: 50%;
  border: 1px solid lightgrey;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  user-select: none;
  .div-hover {
    pointer-events: none;
    display: none;
    border: 3px solid white;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 54px;
    height: 54px;
    border-radius: 50%;
    box-sizing: border-box;
  }
  &:hover {
    border: 3px solid #61c38b;
    div {
      display: flex;
    }

    .title-name-color {
      display: flex;
    }
  }
}

.title-name-color {
  background-color: white;
  position: fixed;

  z-index: 1000;
  padding: 0 5px;
  border: 1px solid black;
  left: 0;
  top: 0;
  display: none;
  white-space: nowrap;
  margin-left: 35px;
  margin-top: -35px;
}

// .arrow-svg {
//   transform: rotateX(180deg);
//   &.rotate {
//     transform: rotateX(0deg);
//   }
// }

.wrapper-accordion-option-checkbox {
  width: 50%;
  display: flex;

  &.width {
    width: 100%;
  }
}

.accordion-option-checkbox {
  display: flex;
  position: relative;
  padding-left: 40px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #000000;
  white-space: nowrap;
}
.accordion-option-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark {
  border: 1px solid black;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  height: 30px;
  width: 30px;
}
.accordion-option-checkbox:hover input ~ .checkmark {
  background-color: #61c38b;
}
.accordion-option-checkbox input:checked ~ .checkmark {
  background-color: #ffffff;
}
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}
.accordion-option-checkbox input:checked ~ .checkmark:after {
  display: block;
}
.accordion-option-checkbox .checkmark:after {
  left: 9px;
  top: 2.5px;
  width: 8px;
  height: 15px;
  border: solid #61c38b;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.accordion-item-content-input {
  width: 100%;
  display: flex;
  height: 60px;
  box-sizing: border-box;

  margin-top: 24px;

  input {
    outline: none;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    border: 3px solid #61c38b;
    width: calc(100% - 60px);
    padding-left: 10px;
  }
}

.accordion-option-send {
  background-color: #61c38b;
  width: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
}

.accordion-btn-allOption {
  margin-top: 15px;
  color: #61c38b;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #61c38b;
  cursor: pointer;
  user-select: none;

  svg {
    margin-top: 5px;
    transform: rotateX(180deg);
    stroke: #61c38b;
    fill: #61c38b;

    &.rotate {
      transform: rotateX(0deg);
    }
  }
}

.container-item-prices {
  justify-content: space-between;
  font-family: 'Heebo';
  font-style: normal;
  // font-weight: 300;
  // font-size: 18px;
  // line-height: 26px;

  // &.all {
  //   padding-top: 20px;
  //   font-weight: 500;
  // }
}

// .container-item-prices-g {
//   display: flex;
//   justify-content: space-between;
//   font-family: 'Heebo';
//   font-style: normal;
//   font-weight: 300;
//   font-size: 18px;
//   line-height: 26px;
//   color: rgb(190, 190, 190);
// }

// .title-item-prices {
//   width: calc(100% - 75px - 30px);
// }

// .title-group-prices {
//   font-weight: 500;
//   width: 70%;
// }

// .currency-item-prices {
//   font-weight: 500;
//   width: 30px;
// }
// .sum-item-prices {
//   width: 75px;
//   display: flex;
//   justify-content: flex-end;
//   font-weight: 500;
// }

// FOOTER

.footer-accordion {
  pointer-events: auto;
  background-color: white;
  display: flex;
  border-top: 1px solid #b1b1b1;
  padding: 11px 30px;
  justify-content: space-between;
}

.item-footer-accordion {
  display: flex;
}

.btn-footer-accordion {
  width: 46px;
  height: 46px;
  box-sizing: border-box;
  border: 1px solid black !important;
  display: flex !important;
  margin-right: 23px;
  align-items: center;
  justify-content: center;
  user-select: none;
}

.item-btn-footer-accordion {
  background-color: #61c38b;
  height: 46px;
  width: 219px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'Heebo', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;

  cursor: pointer;
  user-select: none;

  span {
    padding-left: 5px;
  }
}

.rotate-open {
  transform: rotateZ(180deg);
}

.colFundamente {
  outline: none;
  border: none;
  font-family: 'Heebo';
  font-style: normal;
  width: 70px;
  height: 26px;
  font-size: 16px;
  text-align: center;
  border: 1px solid black;
  padding: 1px 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 27px;
  top: 38px;
}

.colFundamente::-webkit-outer-spin-button,
.colFundamente::-webkit-inner-spin-button {
  -webkit-appearance: none; // Yeah, yeah everybody write about it
}

.colPlattenpassen {
  outline: none;
  border: none;
  font-family: 'Heebo';
  font-style: normal;
  width: 70px;
  height: 26px;
  font-size: 16px;
  text-align: center;
  border: 1px solid black;
  padding: 1px 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 27px;
  transform: translateY(100%);
  top: 50px;
}

.colPlattenpassen::-webkit-outer-spin-button,
.colPlattenpassen::-webkit-inner-spin-button {
  -webkit-appearance: none; // Yeah, yeah everybody write about it
}

.info-icon-btn {
  display: none;
  align-items: center;
  padding-left: 10px;
}

.info-icon-container {
  display: flex;
}

.comment_order {
  padding: 0 27px;
  margin-top: 26px;
}

.title_comment_order {
  font-weight: 700;
}

.comment_comment_order {
  width: 100%;
  height: 140px;
  resize: none;
  outline: 0;
  font-size: 16px;
  font-weight: 300;
  margin-top: 5px;
  margin-left: 1px;
}

.drop-down-dienstleistungen {
  position: absolute;
  right: 0;
  cursor: pointer;
  user-select: none;
}

@media screen and (max-width: 1024px) {
  .accordion-item-content {
    &.color-option {
      // justify-content: flex-start;
      .color-option-color-wrap {
        width: 100% !important;
        // justify-content: space-between;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .info-icon-btn {
    display: flex;
  }

  .info-icon-container {
    display: none;
  }

  .title-name-color {
    transform: translateX(-50%);
  }
}

@media screen and (max-width: 376px) {
  .accordion-item-content {
    &.color-option {
      div {
        justify-content: space-between;
      }
    }
  }
}
