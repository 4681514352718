.wrapper-model-size-modal {
  width: calc(100% - 42px);
  max-width: 695px;
  position: relative;
}

.container-model-size-modal {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 30px;
  padding-bottom: 120px;
}
.section-model-size-modal {
  margin: 32px 0;
  display: flex;
  justify-content: space-between;
}

.title-model-size-modal {
  width: 32px;
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  margin-top: 2px;
}
.input-model-size-modal {
  width: 450px;
  box-sizing: border-box;
  input[type='range'] {
    width: 100%;
    -webkit-appearance: none;
    height: 5px;
    background: #d9d9d9;
    border-radius: 5px;
    background-image: linear-gradient(#61c38b, #61c38b);
    background-repeat: no-repeat;
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 24px;
    width: 24px;
    position: relative;
    opacity: 0;
    cursor: pointer;
  }

  input[type='range']::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }
}

.content-input-model-size-modal {
  position: relative;
  width: 100%;

  input[type='number'] {
    outline: none;
    border: none;
    font-family: 'Heebo';
    font-style: normal;
    width: 36px;
    font-size: 16px;
    text-align: center;

    &:focus {
      border: 1px solid black;
      padding: 1px 11px;
      display: flex;
      justify-content: center;
      align-items: center;
      label {
        top: -50px;
      }
    }
  }
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none; // Yeah, yeah everybody write about it
  }

  input[type='number'],
  input[type='number']:hover,
  input[type='number']:focus {
    appearance: none;
    -moz-appearance: textfield;
  }

  label {
    position: absolute;
    top: -35px;
    width: 100%;
    pointer-events: none;
  }

  span {
    display: flex;
    justify-content: center;
    width: 1px;
    position: relative;
    height: 30px;

    &::before {
      content: '';
      pointer-events: none;
      width: 24px;
      height: 24px;
      position: absolute;
      top: 35px;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 50%;
      background-color: white;
      border: 3px solid #61c38b;
      cursor: pointer;
      box-shadow: 0 0 2px 0 #555;
      transition: background 0.3s ease-in-out;
    }
  }
}

.container-angle {
  display: flex;
  align-items: center;
  height: 24px;
  font-size: 12px;

  background: #ffe0e0;
  border-radius: 5px;
  height: 74px;

  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;

  color: #000000;
  padding-left: 28px;
}

.titles-model-size-modal {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  span {
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.input-model-size-modal-start {
  color: #d8d8d8;
  transform: translateX(-50%);
}

.input-model-size-modal-end {
  color: #d8d8d8;
  transform: translateX(50%);
}

.btn-model-size-modal {
  background-color: #61c38b;
  color: white;
  width: 185px;
  height: 60px;
  position: absolute;
  right: 30px;
  bottom: 30px;
}
